@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
}

.checkmark-container {
  width: 52px;
  height: 52px;
  display: inline-block;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
}

.checkmark-circle {
  fill: #4caf50; /* Green fill for the circle */
  stroke-width: 2;
  stroke: #4caf50; /* Same color as the fill */
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: strokeCircle 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  fill: none;
  stroke-width: 6;
  stroke: white; /* White color for the check */
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: strokeCheck 0.3s 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

@keyframes strokeCircle {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strokeCheck {
  100% {
    stroke-dashoffset: 0;
  }
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* rgb(228, 228, 228); */
}

/* LOADER CSS */

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #243745;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #0a71c7;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

/* LANDING PAGE INFO BOX STYLES */

.info-box-card {
  background-image: linear-gradient(120deg, #f1f2f2 60%, rgba(248, 249, 249, 0)),
    url("../public/images/info-card-background.png");
}
@media (min-width: 768px) {
  .info-box-card {
    background-image: linear-gradient(
        90deg,
        #f1f2f2 60%,
        rgba(248, 249, 249, 0)
      ),
      url("../public/images/info-card-background.png");
  }
}
input[type="text"] {
  font-size: 16px;
}

.uploader {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  /* dashed border */

  /* rx and ry needs to be equal to the border-radius */
  border-radius: 12px;
}
a:not([href]) {
  text-decoration: none;
}
